import styled from 'styled-components';

export const Container = styled.div`
`;

export const HeaderInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const HeaderTitle = styled.h3`
    color: ${props => props.color};
    font-size: calc(15px + 0.390625vw);
`;

export const HeaderArrows = styled.div`
    display: flex;
    gap: 10px;
`;

export const ArrowButton = styled.button`
    margin: 0;
    padding: 0;
    margin-top: 5px;
`;

export const SeeAll = styled.span`
    cursor: pointer;
    font-weight: bold;
    color: ${props => props.color};
    font-size: calc(10px + 0.390625vw);
`;

export const HorizontalScroll = styled.div<{gap: number}>`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    gap: ${(props) => props.gap}px;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const Grid = styled.div<{gap: number, gridMinPx: number, padding?: string}>`
    display: grid;
    justify-items: center;
    width: 100%;
    overflow: auto;
    gap: ${(props) => props.gap}px;
    ${(props) => {
        if(props.padding) {
            return `padding: ${props.padding};`;
        }
    }}

    ::-webkit-scrollbar {
        display: none;
    };

    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    @media(min-width: 330px) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
`;
