import { useEffect } from "react";
import { useAuth } from "../../hooks/auth";
import getTimezoneCountry from "../../utils/getTimezoneCountry";
import i18n from "../../i18n";

const withCountry = (WrappedComponent: any) => {
  return (props: any) => {
    if (typeof window !== 'undefined') {
      const { user } = useAuth();
      const localToken = localStorage.getItem('@coingoback:token');

      useEffect(() => {
        let country = null;
        if(user) {
          if(user.country == null) {
            window.location.href = '/country-selection';
            return
          }

          country = user.country;
        } else if(!localToken) {
          country = getTimezoneCountry();
        }

        if(country) {
          localStorage.setItem('@coingoback:localCountry', country);

          if(country == 'BR') {
            i18n.changeLanguage('pt-BR')
          }
        }
      }, [user]);

      return <WrappedComponent {...props} />;
    }

    return <></>;
  };
};

export default withCountry;
