import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  height: 100vh;
`;

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 35px;
  font-family: ${props => props.theme.font.default};
  font-weight: bold;
  color: #525252;
`;

export const Content = styled.div<{backgroundColor: string}>`
  margin: 30px 0;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
  border-radius: 15px;
  max-height: 200px;

  @media(max-width: 769px) {
    margin: 15px 21px;
  }

  img {
    max-width: 1200px;
    max-height: 200px;
    width: 100%;
  }

  .carousel {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Banner = styled.div<{backgroundColor: string}>`
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
  border-radius: 15px;
  max-height: 200px;

  @media(max-width: 769px) {
    margin: 0px 20px;
  }

  img {
    max-width: 1200px;
    max-height: 200px;
    width: 100%;
  }

  .carousel {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Sections = styled.div`
  max-width: 1071px;
  align-self: center;
  padding: 0 20px;

  @media (max-width: 769px) {
    padding: 0;
  }
  span {
    color: rgb(43, 43, 43);
  }

  .redirect {
    display: flex;
    justify-content: right;
    margin-right: 10px;

    button {
      font-weight: bold;
      font-size: 18px;
      color: rgb(43, 43, 43);
    }
  }
`;

export const FeaturedMenu = styled.div`
  @media (max-width: 769px) {
    display: none;
  }
  max-width: 95vw;

  .title.body {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;

    .arrows {
      width: 30px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  div.content {
    a {
      color: rgb(43, 43, 43);
    }
    @media (max-width: 1088px) {
      width: 95vw;
    }

    display: flex;
    align-items: center;
    height: 270px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 443px) {
      height: 200px;
    }

    @media (max-width: 351px) {
      height: 175px;
    }

    div.cards {
      display: flex;
      flex-shrink: 1;
      gap: 26px;

      button {
        padding-right: 5px;
      }
      a {
        padding-right: 5px;
      }

      .menu.card {
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        justify-content: space-evenly;

        width: 180px;
        height: 180px;
        padding: 0;
        margin: 0;

        align-items: center;
        text-align: center;
        font-weight: bold;

        @media (max-width: 443px) {
          height: 155px;
          width: 155px;
        }

        @media (max-width: 351px) {
          height: 130px;
          width: 130px;
        }
      }
    }
  }
`;

export const FeaturedStore = styled.div`
  .title.body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      @media (max-width: 769px) {
        margin-left: 21px;
      }
    }

    .arrows {
      width: 30px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export const FeaturedStoreCards = styled.div`
  gap: 26px;

  @media (max-width: 425px) {
    gap: 15px;
  }

  div.cards {
    display: flex;
    flex-shrink: 1;
  }

  @media (max-width: 1088px) {
    width: 95vw;
  }
  @media (max-width: 769px) {
    width: 100vw;
  }

  @media (max-width: 443px) {
    height: 200px;
  }

  @media (max-width: 351px) {
    height: 175px;
  }
  height: 300px;
  display: flex;
  overflow: auto;
  justify-content: space-between;

  ::-webkit-scrollbar {
    display: none;
  }
  div.lastcard {
    margin-right: 10px;
    align-self: center;
  }

  .storecards {
   padding-right: 5px;
    &:first-child {
      @media (max-width: 769px) {
        margin-left: 21px;
      }
    }
  }
`;

export const FeaturedBestStores = styled.div`
  .title.body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      @media (max-width: 769px) {
        margin-left: 21px;
      }
    }

    .arrows {
      width: 30px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .GoToStore {
    margin: 10px 10px 10px 0;
    display: flex;
    justify-content: right;
    font-size: 17px;
    font-weight: bold;
    -webkit-tap-highlight-color: transparent;
    button {
      font-size: 17px;
      font-weight: bold;
      color: rgb(43, 43, 43);
    }
  }
`;

export const Baners = styled.div`
  display: flex;

  @media (max-width: 450px) {
    display: block;
  }
  .frist {
    background: #000;
    width: auto;
    @media (min-width: 450px) {
      margin-right: 20px;
    }
  }
  .second {
    background: #ddd;
    width: auto;
  }
`;
export const News = styled.div`
  margin-bottom: 56px;
  .title.body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrows {
      width: 30px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  div.content {
    @media (max-width: 1088px) {
      width: 100vw;
    }

    display: flex;
    align-items: center;
    height: 250px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 443px) {
      height: 200px;
    }

    @media (max-width: 351px) {
      height: 175px;
    }

    div.cards-news {
      display: flex;
      flex-shrink: 1;
    }
  }

  .card-news {
    background: #000;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    justify-content: space-evenly;

    width: 200px;
    height: 200px;
    padding: 0;

    align-items: center;
    text-align: center;
    font-weight: bold;

    @media (max-width: 443px) {
      height: 150px;
      width: 150px;
    }

    @media (max-width: 351px) {
      height: 125px;
      width: 125px;
    }
  }
`;

export const StoreCategories = styled.div`
  margin-bottom: 60px;
`;
